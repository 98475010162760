.footer {
  width: 100%;
  color: #72787E;
  height: 58px;
  background: #141E28;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.footerApp {
  width: 100%;
  color: #72787E;
  height: 58px;
  font-size: 9px;
  background: #141E28;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: #fff;
    text-decoration: none;
  }
}
