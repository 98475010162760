
.header {
  position: relative;
  display: flex;
  justify-content: center;
}


.logo {
  position: fixed;
}
