
.header {
  position: absolute;
  top: 0;
  left: 19%;
  width: 64%;
  height: 70px;
  //background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 20%;
    height: 40%;
  }

  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

}

:global .ant-menu-light {
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  border: 0;
}

:global .ant-menu-horizontal .ant-menu-item {
  padding-inline: 30px
}

:global .ant-menu-title-content {
  color: #333333;
}

:global .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #000;
  font-weight: 600;
}

:global .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #FB1915;
  border-bottom-width: 2px;
  inset-inline: 32px;
}

:global {
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after {
    color: #FB1915;
    border-bottom-color: #FB1915 !important;
    inset-inline: 32px;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    inset-inline: 32px;
  }
}

//   移动端适配
.headerApp {
  position: absolute;
  top: 0;
  left: 19%;
  font-size: 12px;
  width: 260px;
  //background: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 20%;
    height: 20%;
  }

  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
